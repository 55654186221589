import * as g from './global.js';

export function isTouch() {
  if (g.tdev) {
    g.body.classList.remove('no-touch');
  }
}
export function modalMenu() {
  const header = document.getElementById('header'),
    toggle = document.getElementById('toggle');
  toggle.addEventListener('click', (e) => {
    if (g.body.classList.contains('h-opened')) {
      if (!g.mode) {
        g.html.removeAttribute('style');
        g.body.removeAttribute('style');
      }
      g.body.classList.remove('h-opened');
    } else {
      if (!g.mode) {
        g.html.style.overflow = 'hidden';
        g.body.style.overflow = 'hidden';
      }
      g.body.classList.add('h-opened');
    }
  });
}
export function anchorLink() {
  const anchor = document.querySelectorAll('a[href^="#"]');
  for (let i = 0; i < anchor.length; i++) {
    anchor[i].addEventListener('click', (e) => {
      e.preventDefault();
      let target = e.target.hash,
        targetId = document.querySelector(target),
        rectTop = targetId.getBoundingClientRect().top,
        offsetTop = window.pageYOffset,
        scrollTop = (target === '#page') ? rectTop + offsetTop : rectTop + offsetTop - g.headerH;
      window.scrollTo({ top: scrollTop, behavior: 'smooth' });
      g.body.classList.remove('h-opened');
    });
  }
}
export function scrollEvent() {
  const header = document.getElementById('header'),
    toggle = document.getElementById('toggle'),
    main = document.getElementById('main');
  let mainY = main.getBoundingClientRect().top;
  const scrlDisplay = () => {
    mainY = main.getBoundingClientRect().top;
    if (mainY <= g.headerH) {
      g.body.classList.add('h-fixed');
    } else {
      g.body.classList.remove('h-fixed');
    }
  };
  scrlDisplay();
  window.addEventListener('scroll', _.throttle(function () {
    scrlDisplay();
  }, 120), false);
}
export function iframeDefer() {
  let iframe = document.querySelectorAll('.iframe-embed');
  for (let i = 0; i < iframe.length; i++) {
    if (iframe[i].getAttribute('data-src')) {
      iframe[i].setAttribute('src', iframe[i].getAttribute('data-src'));
    }
  }
}
export const photoSlider = new Swiper('.photoSlide .swiper-container', {
  centeredSlides: true,
  slidesPerView: 'auto',
  loop: true,
  speed: 400,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction'
  },
  spaceBetween: 24,
  breakpoints: {
    750: {
      spaceBetween: 48
    }
  }
});
export function photoGallery() {
  let images = document.querySelectorAll('.photoGallery-list .item');
  for (let i = 0; i < images.length; i++) {
    images[i].addEventListener('click', (e) => {
      e.preventDefault();
      BigPicture({
        el: e.target,
        gallery: '.photoGallery-list',
      });
    });
  }
};
