import * as g from './import/global.js';
import * as c from './import/common.js';
c.isTouch();
c.modalMenu();
c.anchorLink();
if (document.querySelector('.photoslide') !== null) {
  c.photoSlider;
}
if (document.querySelector('.photoGallery') !== null) {
  c.photoGallery();
}
window.addEventListener('load', function () {
  if (g.body.classList.contains('p-home')) {
    c.scrollEvent();
  }
  if (document.querySelector('.iframe-embed') !== null) {
    c.iframeDefer();
  }
});
